const sectionClassOptions = [
  { key: "Dafault", colValue: 0, value: "w-full grid-cols-none" },
  { key: "1", value: "grid-cols-1", colValue: 1 },
  {
    key: "2",
    value: "grid-cols-2",
    colValue: 2,
  },
  {
    key: "3",
    value: "grid-cols-2 lg:grid-cols-3",
    colValue: 3,
  },
  {
    key: "4",
    value: "grid-cols-2 sm:grid-cols-3 lg:grid-cols-4",
    colValue: 4,
  },
  {
    key: "5",
    value: "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5",
    colValue: 5,
  },
  {
    key: "6",
    value: "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6",
    colValue: 6,
  },
  {
    key: "7",
    value: "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-7",
    colValue: 7,
  },
  {
    key: "8",
    value: "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8",
    colValue: 8,
  },
  {
    key: "9",
    value: "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-9",
    colValue: 9,
  },
  {
    key: "10",
    value: "grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-10",
    colValue: 10,
  },
  {
    key: "11",
    value: "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-11",
    colValue: 11,
  },
  {
    key: "12",
    value: "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-12",
    colValue: 12,
  },
];
export const returnStaticClass = (value: string) => {
  let newClass: string | undefined = "";
  newClass = sectionClassOptions.find((e) => e.value == value)?.value;
  return newClass ?? "";
};

export const returnColValue = (className: string) => {
  let newClass: number | undefined = 0;
  newClass = sectionClassOptions.find((e) => e.value == className)?.colValue;
  return newClass ?? 0;
};
