"use client";
import React, { useState, useEffect } from "react";
import moment from "moment";

const timerClass =
  "w-8 h-6 flex items-center justify-center bg-black text-white text-sm font-medium";

const Countdown = (props) => {
  const { endTime, timeFormat } = props;

  const [timeObj, setTimeObj] = useState({
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
    timediff: 1,
    dayDiff: "",
  });

  useEffect(() => {
    const timeVar = setInterval(() => {
      const then = moment(new Date(endTime));
      const now = moment();

      const timediff = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
        moment(then, "DD/MM/YYYY HH:mm:ss")
      );
      const dayDiff = then.diff(now, "days");
      const countdown = moment.duration(timediff);
      const days = countdown.days();
      const hours = countdown.hours();
      const minutes = countdown.minutes();
      const seconds = countdown.seconds();

      setTimeObj({
        ...timeObj,
        days,
        dayDiff,
        hours,
        minutes,
        seconds,
        timediff: countdown,
      });
    }, 1000);
    return () => {
      clearInterval(timeVar);
    };
  });

  return (
    <div className="flex flex-wrap items-center mb-2">
      {Math.abs(timeObj.timediff) == 0 ? (
        ""
      ) : (
        <div className="flex gap-1">
          <span className={`${timerClass}`}>{Math.abs(timeObj.dayDiff)}D</span>
          <span className={`${timerClass}`}>{Math.abs(timeObj.hours)}H</span>
          <span className={`${timerClass}`}>{Math.abs(timeObj.minutes)}M</span>
          <span className={`${timerClass}`}>{Math.abs(timeObj.seconds)}S</span>
        </div>
      )}
    </div>
  );
};

export default Countdown;
