"use client";
import React, { Fragment } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Slider from "react-slick";
import SingleBrand from "./SingleBrand";
import { SliderSettings, sectionLayout } from "@/app/(app)/types";

function SampleNextArrow(props: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { onClick } = props;
  return (
    <button
      className="absolute top-1/2 -right-12 z-10 -mt-6 flex h-12 w-10 cursor-pointer items-center justify-center"
      onClick={onClick}
    >
      <BsChevronRight className="text-4xl text-primary" />
    </button>
  );
}

function SamplePrevArrow(props: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { onClick } = props;
  return (
    <button
      className="absolute top-1/2 -left-12 z-10 -mt-6 flex h-12 w-10 cursor-pointer items-center justify-center"
      onClick={onClick}
      style={{
        zIndex: "999",
      }}
    >
      <BsChevronLeft className="text-4xl text-primary" />
    </button>
  );
}

const BrandSliderView: React.FC<{
  slider_setting: SliderSettings;
  brand_section: sectionLayout["data"]["section"][0]["brand_section"];
  item_type: string | undefined;
  item_class_name: string | undefined;
}> = ({ slider_setting, brand_section, item_type, item_class_name }) => {
  let settings;
  const slide = slider_setting;

  try {
    if (
      slide &&
      slide.extra_setting &&
      typeof slide.extra_setting === "string"
    ) {
      settings = JSON.parse(slide.extra_setting);
    }
  } catch (err) {
    console.log(err);
  }

  let combined = {};
  if (!slide) return null; // maybe add a loader here
  if (slide !== undefined) {
    combined = {
      ...slide,
      ...settings,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  } else {
    combined = slide;
  }

  return (
    <Slider {...combined}>
      {brand_section?.brand?.length > 0 &&
        brand_section?.brand?.map((brand, i) => (
          <Fragment key={brand._id}>
            <SingleBrand
              brand={brand}
              item_class_name={item_class_name}
              item_type={item_type}
            />
          </Fragment>
        ))}
    </Slider>
  );
};

export default BrandSliderView;
