'use client';
import { APP_LOGO, APP_NAME, LOGO_ICON } from '@/utils/constans';
import { Skeleton } from '@mantine/core';
import React from 'react';
import '../style.css';
import ImageComponent from '@/components/Image';

const HomePageLoading: React.FC<{ isLoadingMore?: boolean }> = ({ isLoadingMore }) => {
  if (!isLoadingMore) {
    return (
      <div className="container mx-auto pb-6">
        {[1, 2].map((x) => (
          <div key={x} className="my-2 md:my-4">
            <div className="title-block sm:py-1 md:py-2 lg:py-4 border-b border-gray-100 my-2 md:my-4">
              <Skeleton height={32} />
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2">
              {[1, 2, 3, 4, 5].map((y) => (
                <div key={`home-page-${y}`}>
                  <div className="bg-gray-200 relative w-full h-[200px] animate-pulse grayscale flex justify-center items-center px-6" key={`home-page-loading-${y}`}></div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (isLoadingMore) {
    return (
      <div className="addressLoadingContainer my-4">
        <div className="dualRingSpinnerAddress" />
        <div className="addressLogoImg">
          <div className="relative w-full h-full">
            <ImageComponent imgObj={LOGO_ICON} alt={APP_NAME} className="object-cover" />
          </div>
        </div>
      </div>
    );
  }

  return false;
};

export default HomePageLoading;
