"use client";
import React, { FC } from "react";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import BasicSquare from "../CardSample/BasicSquare";
import { sectionLayout } from "@/app/(app)/types";
import BigImageSquare from "../CardSample/BigImageSquare";
import RoundedImageComponent from "../CardSample/BasicRound";
import BigRoundedImageComponent from "../CardSample/BigImageRound";

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-1/2 -right-14 z-10 -mt-6 flex cursor-pointer items-center justify-center bg-white shadow-md rounded-full p-4"
      onClick={onClick}
    >
      <BsChevronRight className="text-2xl" />
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-1/2 -left-12 z-10 -mt-6 flex cursor-pointer items-center justify-center bg-white shadow-md rounded-full p-4"
      onClick={onClick}
    >
      <BsChevronLeft className="text-2xl" />
    </div>
  );
}

const CategorySliderView: FC<{
  category: sectionLayout["data"]["section"][0];
  item_class_name: string;
  item_type: string;
}> = ({ category, item_class_name, item_type }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {category.map((cat, idx) => (
        <div key={`${cat._id}-${idx}-catslider`} className="px-2">
          {item_type == "basic_square" && (
            <div className="flex justify-center">
              <BasicSquare category={cat} item_class_name={item_class_name} />
            </div>
          )}
          {item_type === "big_image_square" && (
            <div className="flex justify-center">
              <BigImageSquare
                category={cat}
                item_class_name={item_class_name}
              />
            </div>
          )}
          {item_type === "basic_round" && (
            <div className="flex justify-center">
              <RoundedImageComponent
                category={cat}
                item_class_name={item_class_name}
              />
            </div>
          )}
          {item_type === "big_image_round" && (
            <div className="flex justify-center">
              <BigRoundedImageComponent
                category={cat}
                item_class_name={item_class_name}
              />
            </div>
          )}
        </div>
      ))}
    </Slider>
  );
};

export default CategorySliderView;
