import React, { FC } from "react";
import Link from "next/link";
import Countdown from "@/components/Countdown";

const SectionHeaders: FC<{
  section_link?: string;
  section_title: string;
  section_right_title_text?: string;
  countdown?: {
    is_end: boolean;
    end_date: string;
  };
  is_link: boolean;
}> = ({
  section_link,
  section_title,
  section_right_title_text,
  countdown,
  is_link,
}) => {
  const returnSectionTitle = is_link ? (
    <Link
      href={section_link || "/"}
      prefetch={false}
      className="text-2xl font-semibold hover:underline"
    >
      {section_title}
    </Link>
  ) : (
    <div className="text-2xl font-semibold hover:underline">
      {section_title}
    </div>
  );

  return (
    <div className="flex items-center justify-between py-2">
      {!countdown?.is_end ? (
        returnSectionTitle
      ) : (
        <div className="lg:flex gap-4 items-center">
          {returnSectionTitle}
          {countdown.is_end !== undefined && countdown.is_end ? (
            <>
              {countdown.end_date !== undefined &&
                countdown.end_date !== "" && (
                  <Countdown
                    endTime={countdown.end_date}
                    timeFormat="MM DD YYYY, h:mm a"
                  />
                )}
            </>
          ) : null}
        </div>
      )}
      {is_link && (
        <Link
          href={section_link || "/"}
          prefetch={false}
          className="underline text-sm"
        >
          {section_right_title_text || "See All"}
        </Link>
      )}
    </div>
  );
};

export default SectionHeaders;
