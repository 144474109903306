"use client";
import { Fragment, useEffect, useMemo, useState } from "react";
import { LAYOUT_SECTION_SIZE } from "@/utils/constans";
import { fetcher, options } from "@/utils/Api";
import useSWR from "swr";
import { queryHelper } from "@/utils/helpers";
import HomePageLoading from "../Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import CategorySection from "../components/CategorySection";
import ContentSection from "../components/ContentSection";
import ProductSection from "../components/ProductSection";
import MenuSection from "../components/MenuSection";
import SliderSection from "../components/SliderSection";
import BrandSection from "../components/BrandSection";
import { sectionLayout } from "@/app/(app)/types";

const ClientSideHomePageContainer = () => {
  const [query, setQuery] = useState({ page: 2, size: LAYOUT_SECTION_SIZE });
  const [scrolledToBottom, setScrolledToBottom] = useState(false);

  const {
    data,
    isLoading: loading,
    mutate: pageLayoutMutate,
  }: {
    data: sectionLayout;
    isLoading: boolean;
    mutate: Function;
  } = useSWR(
    scrolledToBottom
      ? {
          url: `layout/key/home-layout?page=2&size=${LAYOUT_SECTION_SIZE}`,
          headers: options,
        }
      : null,
    fetcher,
    { revalidateOnFocus: false }
  );

  const handleFetchMoreData = async () => {
    setQuery({
      ...query,
      page: query.page + 1,
    });
    const res = await fetcher({
      url: `layout/key/home-layout?${queryHelper({
        ...query,
        page: query.page + 1,
      })}`,
      headers: options,
    });
    if (res?.success) {
      pageLayoutMutate(
        (prev: any) => {
          let data = {
            data: {
              ...res.data,
              section: [...prev.data.section, ...res.data.section],
            },
            page: res.page,
            size: res.size,
            totalData: res.totalData,
          };
          return data;
        },
        { revalidate: false }
      );
    }
  };

  useEffect(() => {
    if (!scrolledToBottom) {
      window.onscroll = function () {
        const thresHold = 150;
        const maxScrollPosition =
          document.documentElement.scrollHeight -
          window.innerHeight -
          thresHold;
        const scrollY = window.scrollY;
        if (scrollY > maxScrollPosition) {
          setScrolledToBottom(true);
        }
      };
      return () => {
        window.onscroll = null;
      };
    }
  }, []);

  const hasMoreBool = useMemo(() => {
    const sectionLength = data?.data?.section?.length + LAYOUT_SECTION_SIZE;
    if (data?.totalData == sectionLength) {
      return false;
    } else if (data?.totalData > sectionLength) {
      return true;
    } else {
      return false;
    }
  }, [data]);

  return (
    <>
      <InfiniteScroll
        dataLength={data?.data?.section?.length ?? 0}
        next={handleFetchMoreData}
        hasMore={hasMoreBool}
        loader={<HomePageLoading />}
        style={{
          overflow: "visible",
          paddingBottom: "40px",
          background: "white",
        }}
      >
        {data?.data?.section?.length > 0 &&
          data?.data?.section.map((section, sectionIdx) => (
            <Fragment key={`${section._id}-${sectionIdx}`}>
              {section?.category_section &&
                section?.category_section?.category && (
                  <CategorySection {...section} />
                )}
              {section?.content_section?.description && (
                <ContentSection {...section} />
              )}
              {section?.product_section?.product?.length > 0 && (
                <ProductSection {...section} />
              )}
              {section?.menu_section && <MenuSection {...section} />}
              {section?.slider_section && <SliderSection {...section} />}
              {section?.brand_section?.brand && <BrandSection {...section} />}
            </Fragment>
          ))}
      </InfiniteScroll>
    </>
  );
};

export default ClientSideHomePageContainer;
