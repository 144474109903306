import { sectionLayout } from "@/app/(app)/types";
import { returnImgSrc } from "@/utils/helpers";

const ContentSection = ({
  content_section,
  class_name,
  layout_class,
  custom_class,
  section_class,
  is_header,
  name,
}: sectionLayout["data"]["section"][0]) => {
  return (
    <div
      className={`${class_name} ${layout_class ? layout_class : ""} ${
        custom_class ? custom_class : ""
      }`}
    >
      <div className={`${section_class ? section_class : "container mx-auto"}`}>
        {is_header && (
          <div className="my-2 flex items-center justify-between py-2 font-bold md:my-4">
            <h2 className="m-0 pr-4 text-base font-bold text-secondary md:text-lg lg:text-2xl">
              {`${is_header ? name : content_section.name}`}
            </h2>
          </div>
        )}
      </div>
      <div>
        {content_section &&
          content_section.image &&
          content_section.image.path && (
            <div className="relative w-20 h-20">
              <img
                className="object-contain"
                src={returnImgSrc(content_section.image)}
                alt="Layout Content"
              />
            </div>
          )}
        <div
          dangerouslySetInnerHTML={{
            __html: content_section.description,
          }}
        />
      </div>
    </div>
  );
};

export default ContentSection;
