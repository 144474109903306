"use client";
import { SliderSettings, sectionLayout } from "@/app/(app)/types";
import React, { Fragment } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Slider from "react-slick";
import CardSampleOne from "../components/CardTypes/Basic";
import CardSampleTwo from "../components/CardTypes/BigImage";
import CardSampleThree from "../components/CardTypes/BasicWithAddToCart";
import CardSampleFour from "../components/CardTypes/BigImageWithAddToCart";

function SampleNextArrow(props: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { onClick } = props;
  return (
    <button
      className="absolute top-1/2 -right-12 z-10 -mt-6 flex cursor-pointer items-center justify-center text-primary bg-gray-200 p-1.5 rounded-full text-xl shadow-md"
      onClick={onClick}
    >
      <BsChevronRight className="text-4xl text-primary" />
    </button>
  );
}

function SamplePrevArrow(props: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { onClick } = props;
  return (
    <button
      className="absolute top-1/2 -left-12 z-10 -mt-6 flex cursor-pointer items-center justify-center text-primary bg-gray-200 p-1.5 rounded-full text-xl shadow-md"
      onClick={onClick}
      style={{
        zIndex: "999",
      }}
    >
      <BsChevronLeft className="text-4xl text-primary" />
    </button>
  );
}

const SliderView: React.FC<{
  slider_setting: SliderSettings;
  item_type: string;
  product_section: sectionLayout["data"]["section"][0]["product_section"];
}> = ({ slider_setting, item_type, product_section }) => {
  let settings;
  const slide = slider_setting;
  try {
    if (
      slide &&
      slide.extra_setting &&
      typeof slide.extra_setting === "string"
    ) {
      settings = JSON.parse(slide.extra_setting);
    }
  } catch (err) {
    console.log(err);
  }

  let combined = {};
  if (!slide) return null; // maybe add a loader here
  if (slide !== undefined) {
    combined = {
      ...slide,
      ...settings,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  } else {
    combined = slide;
  }

  const showProduct = ({
    is_published,
    is_deleted,
    is_active_seller,
  }: {
    is_published: boolean;
    is_deleted: boolean;
    is_active_seller: boolean;
  }) => {
    let show = true;
    if (is_published === false) {
      show = false;
    }
    if (is_deleted == true) {
      show = false;
    }
    if (is_active_seller === false) {
      show = false;
    }
    return show;
  };

  return (
    <Slider {...combined}>
      {product_section?.product?.length > 0 &&
        product_section?.product.map((product, i: number) => (
          <Fragment key={i}>
            {showProduct({
              is_published: product?.is_published,
              is_deleted: product?.is_deleted,
              is_active_seller: product?.is_active_seller,
            }) ? (
              <React.Fragment key={product._id}>
                <div className="flex justify-center">
                  {item_type && item_type === "basic" && (
                    <CardSampleOne product={product} productIndex={i} />
                  )}
                  {item_type && item_type === "big_image" && (
                    <CardSampleTwo product={product} productIndex={i} />
                  )}
                  {item_type && item_type === "basic_with_addtocart" && (
                    <CardSampleThree product={product} productIndex={i} />
                  )}
                  {item_type && item_type === "big_image_with_addtocart" && (
                    <CardSampleFour product={product} productIndex={i} />
                  )}
                </div>
              </React.Fragment>
            ) : null}
          </Fragment>
        ))}
    </Slider>
  );
};

export default SliderView;
