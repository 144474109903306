import { sectionLayout } from "@/app/(app)/types";
import { returnStaticClass } from "@/components/ResponsiveCols";
import { returnImgSrc } from "@/utils/helpers";
import Link from "next/link";
import MenuSliderView from "./SliderView";

const MenuSection = ({
  layout_class,
  class_name,
  custom_class,
  is_header,
  section_class,
  menu_section,
  name,
  slider_setting,
  view_type,
}: sectionLayout["data"]["section"][0]) => {
  return (
    <>
      {name !== "" && (
        <div
          className={`${section_class ? section_class : "container mx-auto"}`}
        >
          {is_header && (
            <div className="flex justify-center items-center font-bold py-10">
              <h2 className="text-2xl font-bold">{`${name}`}</h2>
            </div>
          )}
        </div>
      )}
      {view_type == "Grid" && (
        <div
          className={`container mx-auto lg:grid gap-4 ${returnStaticClass(
            class_name
          )}`}
        >
          {menu_section?.child?.length > 0 &&
            menu_section?.child.map((child, idx: number) => (
              <Link
                href={`${child.url}`}
                target={child.target}
                prefetch={false}
                key={`${child._id}-childmenu-${idx}`}
                className={`${custom_class || ""}`}
              >
                <div className={`relative w-full aspect-square`}>
                  <img
                    src={returnImgSrc(child.image)}
                    alt={child.title}
                    className="h-auto w-full object-contain"
                  />
                </div>
              </Link>
            ))}
        </div>
      )}
      {view_type == "Slider" && (
        <MenuSliderView
          menu_section={menu_section}
          custom_class={custom_class}
          slider_setting={slider_setting}
        />
      )}
    </>
  );
};

export default MenuSection;
