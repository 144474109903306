import { returnStaticClass } from "@/components/ResponsiveCols";
import React from "react";
import "../../style.css";
import SectionHeaders from "../SectionComponents/Headers";
import BasicSquare from "./CardSample/BasicSquare";
import BigImageSquare from "./CardSample/BigImageSquare";
import CategorySliderView from "./ViewType/Slider";
import { sectionLayout } from "@/app/(app)/types";
import RoundedImageComponent from "./CardSample/BasicRound";
import BigRoundedImageComponent from "./CardSample/BigImageRound";

const CategorySection = ({
  category_section,
  view_type,
  class_name,
  custom_class,
  is_header,
  name,
  section_class,
  section_class_name,
  layout_class,
  slider_setting,
  item_class_name,
  item_type,
}: sectionLayout["data"]["section"][0]) => {
  return (
    <>
      <div
        className={`layout_slider ${class_name} ${
          layout_class ? layout_class : ""
        } ${custom_class ? custom_class : ""}`}
      >
        <div
          className={`${
            section_class
              ? section_class
              : "container mx-auto my-2 lg:my-5 px-2 lg:px-0 mt-4"
          }`}
        >
          {is_header && (
            <SectionHeaders
              section_link={`/sections/category/${category_section?.group_key}`}
              section_title={name}
              is_link
            />
          )}
          {view_type == "Slider" && category_section?.category?.length > 0 && (
            // <div className="flex justify-center">
            <CategorySliderView
              category={category_section.category}
              item_class_name={item_class_name}
              item_type={item_type}
            />
            // </div>
          )}
          {view_type == "Grid" && category_section?.category?.length > 0 && (
            <div
              className={`overflow-auto grid gap-4 mt-4 ${
                class_name ? returnStaticClass(class_name) : ""
              }`}
            >
              {category_section?.category?.map((category, catIdx: number) => (
                <React.Fragment key={`${category._id}-${catIdx}-catGrid`}>
                  <div className="flex justify-center">
                    {item_type === "basic_square" && (
                      <BasicSquare
                        category={category}
                        item_class_name={item_class_name}
                      />
                    )}{" "}
                    {item_type === "big_image_square" && (
                      <BigImageSquare
                        category={category}
                        item_class_name={item_class_name}
                        imageSize="200-200"
                      />
                    )}
                    {item_type === "basic_round" && (
                      <RoundedImageComponent
                        item_class_name={item_class_name}
                        category={category}
                      />
                    )}
                    {item_type === "big_image_round" && (
                      <BigRoundedImageComponent
                        item_class_name={item_class_name}
                        category={category}
                        imageSize="200-200"
                      />
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CategorySection;
