import { returnImgSrc } from "@/utils/helpers";
import Link from "next/link";
import React from "react";
import ImageComponent from "@/components/Image";
import { sectionLayout } from "@/app/(app)/types";

const SingleBrand: React.FC<{
  brand: sectionLayout["data"]["section"][0]["brand_section"]["brand"][0];
  item_type: string | undefined;
  item_class_name: string | undefined;
}> = ({ brand, item_type, item_class_name }) => {
  return (
    <>
      {item_type && item_type === "CardOne" && (
        <div className={`${item_class_name ? item_class_name : ""}`}>
          <Link
            href={`/brand/${brand.url_key}`}
            prefetch={false}
            key={brand._id}
          >
            <ImageComponent imgObj={brand?.logo} size="200-200" />
          </Link>
        </div>
      )}
      {item_type && item_type === "CardTwo" && (
        <div
          className={`p-px
              ${item_class_name ? item_class_name : ""}`}
        >
          <Link
            href={`/brand/${brand.url_key}`}
            prefetch={false}
            key={brand._id}
          >
            <div className="bg-white flex flex-col items-center justify-center w-full py-3 text-sm shadow relative">
              <img
                src={returnImgSrc(brand?.logo, "250-200")}
                className="w-auto h-28"
                alt={brand.title}
              />
              <span className="block pb-2.5 font-bold">{brand.title}</span>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default SingleBrand;
