import type { ButtonProps as MantineButtonProps } from "@mantine/core";
import { Button as MantineButton } from "@mantine/core";
import { FC, MouseEventHandler } from "react";
import "./style.css";

interface ButtonProps extends MantineButtonProps {
  onClick?: MouseEventHandler;
  type?: "submit" | "reset" | "submit";
  ariaLabel?: string;
  id?: string;
}

const Button: FC<ButtonProps> = ({
  onClick,
  children,
  loading,
  disabled,
  fullWidth,
  type,
  className,
  color,
  leftSection,
  rightSection,
  ariaLabel,
  id,
  ...restProps
}) => {
  return (
    <MantineButton
      loading={loading}
      leftSection={leftSection}
      rightSection={rightSection}
      onClick={onClick}
      disabled={disabled}
      id={id}
      aria-label={ariaLabel}
      type={type}
      fullWidth={fullWidth || false}
      color={color || "primary"}
      className={className}
      {...restProps}
    >
      {children}
    </MantineButton>
  );
};

export default Button;
