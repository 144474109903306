"use client";
import Countdown from "@/components/Countdown";
import Link from "next/link";
import { RiArrowRightSLine } from "react-icons/ri";
import GridView from "../../View/Grid";
import Slider from "../../View/Slider";
import { sectionLayout } from "@/app/(app)/types";
import SectionHeaders from "../SectionComponents/Headers";

const ProductSection = ({
  layout_class,
  class_name,
  custom_class,
  is_header,
  section_class,
  product_section,
  name,
  view_type,
  item_type,
  slider_setting,
}: sectionLayout["data"]["section"][0]) => {
  return (
    <section
      className={`${
        layout_class ? layout_class : "container mx-auto px-5 lg:px-0"
      } mb-6`}
    >
      <div className={`${section_class ? section_class : ""}`}>
        {is_header && (
          <SectionHeaders
            section_link={`/sections/product/${product_section?.group_key}`}
            section_title={name}
            countdown={{
              is_end: product_section.is_end,
              end_date: product_section.end_date,
            }}
            is_link
          />
        )}
        {view_type == "Grid" && (
          <GridView
            product_section={product_section}
            class_name={class_name}
            item_type={item_type}
          />
        )}
        {view_type == "Slider" && (
          <Slider
            product_section={product_section}
            slider_setting={slider_setting}
            item_type={item_type}
          />
        )}
      </div>
    </section>
  );
};

export default ProductSection;
