"use client";
import ImageComponent from "@/components/Image";
import Link from "next/link";
import React from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Slider from "react-slick";
import { SliderSettings, sectionLayout } from "@/app/(app)/types";

function SampleNextArrow(props: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { onClick } = props;
  return (
    <button
      className="absolute top-1/2 -right-12 z-10 -mt-6 flex h-12 w-10 cursor-pointer items-center justify-center"
      onClick={onClick}
    >
      <BsChevronRight className="text-4xl text-primary" />
    </button>
  );
}

function SamplePrevArrow(props: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { onClick } = props;
  return (
    <button
      className="absolute top-1/2 -left-12 z-10 -mt-6 flex h-12 w-10 cursor-pointer items-center justify-center"
      onClick={onClick}
      style={{
        zIndex: "999",
      }}
    >
      <BsChevronLeft className="text-4xl text-primary" />
    </button>
  );
}

const MenuSliderView: React.FC<{
  custom_class: string;
  menu_section: sectionLayout["data"]["section"][0]["menu_section"];
  slider_setting: SliderSettings;
}> = ({ custom_class, menu_section, slider_setting }) => {
  let settings;
  const slide = slider_setting;

  try {
    if (
      slide &&
      slide.extra_setting &&
      typeof slide.extra_setting === "string"
    ) {
      settings = JSON.parse(slide.extra_setting);
    }
  } catch (err) {
    console.log(err);
  }

  let combined = {};
  if (!slide) return null; // maybe add a loader here
  if (slide !== undefined) {
    combined = {
      ...slide,
      ...settings,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  } else {
    combined = slide;
  }

  return (
    <Slider {...combined}>
      {menu_section?.child?.length > 0 &&
        menu_section?.child.map((child, idx) => (
          <Link
            href={`${child.url}`}
            target={child.target}
            prefetch={false}
            key={`${child._id}-childmenu-${idx}`}
            className={`${custom_class || ""}`}
          >
            <div className={`relative h-[340px] w-full `}>
              <ImageComponent
                imgObj={child.image}
                alt={child.title}
                className="h-auto w-full object-contain"
              />
            </div>
          </Link>
        ))}
    </Slider>
  );
};

export default MenuSliderView;
