"use client";
import { sectionLayout } from "@/app/(app)/types";
import ImageComponent from "@/components/Image";
import { returnStaticClass } from "@/components/ResponsiveCols";
import { returnImgSrc } from "@/utils/helpers";
import Link from "next/link";
import React, { Fragment } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Slider from "react-slick";
import SectionHeaders from "../SectionComponents/Headers";
import "./styles.css";

function SampleNextArrow(props: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { onClick } = props;
  return (
    <button
      title="Previous"
      className="absolute right-0 top-1/2 z-10 -translate-y-1/2"
      onClick={onClick}
    >
      <BsChevronRight className="text-2xl text-white opacity-60 hover:opacity-100 duration-200 lg:text-8xl" />
    </button>
  );
}

function SamplePrevArrow(props: {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { onClick } = props;
  return (
    <button
      title="Next"
      className="absolute top-1/2 z-10 -translate-y-1/2"
      onClick={onClick}
    >
      <BsChevronLeft className="text-2xl text-white opacity-60 hover:opacity-100 duration-200 lg:text-8xl" />
    </button>
  );
}

const SliderSection: React.FC<sectionLayout["data"]["section"][0]> = ({
  layout_class,
  class_name,
  custom_class,
  is_header,
  section_class,
  name,
  slider_section,
  view_type,
  slider_setting,
}) => {
  // slider_setting : slider settings of home page groups;
  // slider_section.slider_setting : slider settings of slider section;

  if (view_type == "Slider") {
    // parse json from extra_settings;
    // used for responsive settings;
    const returnSliderSettings = () => {
      let settings = {};
      try {
        if (
          slider_setting?.extra_setting &&
          typeof slider_setting.extra_setting === "string"
        ) {
          settings = JSON.parse(slider_setting.extra_setting);
        }
      } catch (err) {
        settings = {};
      }

      return {
        ...slider_setting,
        ...settings,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
      };
    };

    return (
      <>
        <div
          className={`${layout_class ? layout_class : "container mx-auto"} ${custom_class || ""}`}
        >
          {is_header && <SectionHeaders section_title={name} is_link={false} />}
          <Slider {...returnSliderSettings()}>
            {slider_section.images.map((image) => (
              <Fragment key={image._id}>
                {image?.image?.path ? (
                  <>
                    {image?.link !== "" ? (
                      <Link
                        href={image.link ? image.link : ""}
                        prefetch={false}
                        target="_blank"
                      >
                        <ImageComponent
                          imgObj={image.image}
                          alt="Carousel-Image"
                          className="w-full"
                        />
                      </Link>
                    ) : (
                      <ImageComponent
                        imgObj={image.image}
                        alt="Carousel-Image"
                        className="w-full"
                      />
                    )}
                  </>
                ) : (
                  <div
                    className="w-full ckEditor absolute transform left-1/2 top-1/2 -translate-1/2 -translate-1/2"
                    dangerouslySetInnerHTML={{
                      __html: image.caption,
                    }}
                  />
                )}
              </Fragment>
            ))}
          </Slider>
        </div>
      </>
    );
  }

  if (view_type === "Grid") {
    return (
      <>
        <div
          className={`${
            layout_class ? layout_class : "container mx-auto my-4"
          }`}
        >
          {is_header && (
            <div className="flex justify-between items-center font-bold py-2 my-2 md:my-4 border-b-2 border-primary">
              <h2 className="text-base md:text-lg lg:text-2xl font-bold m-0 pr-4 text-primary">
                {`${name}`}
              </h2>
            </div>
          )}
          <div
            className={`overflow-auto flex lg:grid ${returnStaticClass(
              class_name
            )} gap-2`}
          >
            {slider_section.images.map((image) => (
              <div key={image._id}>
                {image && image.image && image.image.path ? (
                  <>
                    <Link
                      href={image.link ? image.link : ""}
                      prefetch={false}
                      key={image._id}
                    >
                      <img
                        src={returnImgSrc(image.image)}
                        loading="lazy"
                        alt={image.filename}
                      />
                    </Link>
                  </>
                ) : (
                  <div
                    className="w-full ckEditor absolute transform left-1/2 top-1/2 -translate-1/2 -translate-1/2"
                    dangerouslySetInnerHTML={{
                      __html: image.caption,
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  return <></>;
};

export default SliderSection;
