import { returnStaticClass } from "@/components/ResponsiveCols";
import React from "react";
import CardSampleOne from "../components/CardTypes/Basic";
import CardSampleTwo from "../components/CardTypes/BigImage";
import { sectionLayout } from "@/app/(app)/types";
import CardSampleThree from "../components/CardTypes/BasicWithAddToCart";
import CardSampleFour from "../components/CardTypes/BigImageWithAddToCart";

const GridView: React.FC<{
  class_name: string;
  product_section: sectionLayout["data"]["section"][0]["product_section"];
  item_type: string;
}> = ({ class_name, product_section, item_type }) => {
  return (
    <div
      className={`overflow-auto flex lg:grid ${returnStaticClass(
        class_name
      )} gap-4`}
    >
      {product_section?.product.map((product, prodIdx) => (
        <div className="flex-1" key={`${product._id}-${prodIdx}-prodSec`}>
          {item_type == "basic" && (
            <CardSampleOne product={product} productIndex={prodIdx} />
          )}
          {item_type == "big_image" && (
            <CardSampleTwo product={product} productIndex={prodIdx} />
          )}
          {item_type == "basic_with_addtocart" && (
            <CardSampleThree product={product} productIndex={prodIdx} />
          )}
          {item_type == "big_image_with_addtocart" && (
            <CardSampleFour product={product} productIndex={prodIdx} />
          )}
        </div>
      ))}
    </div>
  );
};

export default GridView;
