import ImageComponent from "@/components/Image";
import Link from "next/link";
import { FC } from "react";
import "./styles.css";
import { sectionLayout } from "@/app/(app)/types";

interface BigImageSquareProps {
  item_class_name: sectionLayout["data"]["section"][0]["item_class_name"];
  category: sectionLayout["data"]["section"][0]["category_section"]["category"][0];
  imageSize?: string; // Optional prop to specify the size of the image
}

const BigImageSquare: FC<BigImageSquareProps> = ({
  item_class_name,
  category,
  imageSize = "200-200",
}) => {
  if (!category || !category.url) {
    return null;
  }

  return (
    <>
      <div
        className={`${item_class_name ? item_class_name : ""} big_image_square_main`}
      >
        <Link href={`/category/${category.url}`} prefetch={false}>
          <div className="relative block overflow-hidden my-2">
            <ImageComponent
              imgObj={category?.image}
              size={imageSize}
              className="w-full h-full object-cover ease-in-out transition-all hover:scale-110 duration-150"
            />
          </div>
        </Link>
        {/* <Link
          className="flex justify-center pt-4 font-bold cursor-pointer text-base duration-100 pb-3 w-full"
          href={`/category/${category.url}`}
        >
          <span className="relative big_image_square_title block pb-2.5">
            {category.title}
          </span>
        </Link> */}
      </div>
    </>
  );
};

export default BigImageSquare;
