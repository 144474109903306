import ImageComponent from "@/components/Image";
import Link from "next/link";
import { FC } from "react";
import "./styles.css";
import { sectionLayout } from "@/app/(app)/types";

const CardSampleOne: FC<{
  item_class_name: sectionLayout["data"]["section"][0]["item_class_name"];
  category: sectionLayout["data"]["section"][0]["category_section"]["category"][0];
}> = ({ item_class_name, category }) => {
  if (!category || !category.url) {
    return null; // or handle this case as per your requirement
  }

  return (
    <>
      <div
        className={` ${
          item_class_name ? item_class_name : ""
        } category_card_sample_main`}
      >
        <Link href={`/category/${category.url}`} prefetch={false}>
          <div className="relative block rounded-full overflow-hidden my-2">
            <ImageComponent
              imgObj={category?.image}
              size="200-200"
              className="w-[164px] h-[164px] object-scale-down ease-in-out transition-all hover:scale-110 duration-150"
            />
          </div>
        </Link>
        <Link
          className="flex justify-center pt-4 font-bold cursor-pointer text-base duration-100 pb-3 w-full"
          href={`/category/${category.url}`}
        >
          <span className="relative category_card_sample_title block pb-2.5">
            {category.title}
          </span>
        </Link>
      </div>
    </>
  );
};

export default CardSampleOne;
