import { sectionLayout } from "@/app/(app)/types";
import { returnColValue, returnStaticClass } from "@/components/ResponsiveCols";
import React, { Fragment } from "react";
import "../../style.css";
import SectionHeaders from "../SectionComponents/Headers";
import SingleBrand from "./SingleBrand";
import BrandSliderView from "./SliderView";

const BrandSection: React.FC<sectionLayout["data"]["section"][0]> = ({
  layout_class,
  class_name,
  custom_class,
  section_class,
  is_header,
  name,
  brand_section,
  view_type,
  item_type,
  item_class_name,
  slider_setting,
}) => {
  const returnSpliceArray = (class_name: string) => {
    let returnedSec: Array<
      sectionLayout["data"]["section"][0]["brand_section"]["brand"][0]
    > = [];
    const colValue = returnColValue(class_name);
    if (colValue) {
      returnedSec = brand_section.brand.slice(0, colValue);
    }
    return returnedSec;
  };

  return (
    <section className={`${layout_class ? layout_class : ""}`}>
      <div
        className={`${section_class ? section_class : "container mx-auto my-2 lg:my-5 px-2 lg:px-0"}`}
      >
        {is_header && (
          <SectionHeaders
            section_link={`/sections/brand/${brand_section?.group_key}`}
            section_title={name}
            is_link
          />
        )}

        {view_type == "Grid" && (
          <div
            className={`grid lg:px-0 py-2 gap-4 items-center ${
              class_name
                ? returnStaticClass(class_name)
                : "grid-cols-2 lg:grid-cols-5"
            }`}
          >
            {returnSpliceArray(class_name)?.length > 0 &&
              returnSpliceArray(class_name).map((brand, i) => (
                <Fragment key={brand._id}>
                  <SingleBrand
                    brand={brand}
                    item_class_name={item_class_name}
                    item_type={item_type}
                  />
                </Fragment>
              ))}
          </div>
        )}
        {view_type == "Slider" && (
          <BrandSliderView
            brand_section={brand_section}
            item_class_name={item_class_name}
            item_type={item_type}
            slider_setting={slider_setting}
          />
        )}
      </div>
    </section>
  );
};

export default BrandSection;
